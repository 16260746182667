<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col sm="12" class="text-center mb-4">
          <h1>{{ $t('signin.resetPassword')}}</h1>
        </b-col>
        <b-col md="6" offset-md="3" lg="4" offset-lg="4">
          <div v-if="successfullyChangedPass">
             <div class="d-block valid-feedback mb-3"> {{ $t('signin.successfullyChangedPassword') }}</div>
          </div>
          <div v-else>
          <p>
            <b-form-input id="email" type="password" :state="passwordState" v-model="password" :placeholder="$t('signin.enterNewPassword')" autocomplete="new-password" trim></b-form-input>
          </p>
          <p>
            <b-form-input id="email" type="password" :state="passwordState" v-model="repeatedPassword" :placeholder="$t('signin.repeatNewPassword')" autocomplete="new-password" trim></b-form-input>
          </p>
          <p>
            <b-form-invalid-feedback :state="passwordState">{{ $t('signin.enter-repeated-password') }}</b-form-invalid-feedback>
          </p>
          <div class="text-center">
            <b-overlay :show="buttonSpinner" spinner-small spinner-variant="secondary" opacity="0.5" class="d-inline-block">
              <button type="submit" :disabled="buttonSpinner" class="btn btn-primary" @click="resetPass">{{ $t('signin.changePassword') }}</button>
            </b-overlay>
          </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name : "ResetPassword",
  data() {
    return {
      passwordHash : this.$route.params.hash,
      password: "",
      repeatedPassword: "",
      userId : "",
      passwordState: null,
      buttonSpinner: false,
      successfullyChangedPass: false
    }
  },
  created() {
      const passwordResetDataHash = window.atob(this.$route.params.hash);
      const passwordResetDataArray = passwordResetDataHash.split("|");
      const dateUnixTimestamp = parseInt(passwordResetDataArray[1]);
      this.userId = passwordResetDataArray[0];
      const jsDate = new Date(dateUnixTimestamp * 1000);
  },
  methods: {

    async resetPass() {
      if (this.validateForm()) {
        this.buttonSpinner = true;
        const myData = {
          userId : this.userId,
          password: this.password,
          repeatedPassword: this.repeatedPassword
        }
        await this.$http.post('/api/users/set-new-password', {data: this.encodeJSON(myData)}).then(r => {
          if (r.data === "OK") {
            this.successfullyChangedPass = true;
          }
        }).catch(e => {  });
        this.buttonSpinner = false;
      }
    },

    validateForm() {
       if (this.password.length === 0 || (this.password !== this.repeatedPassword)) {
          this.passwordState = false;
          document.getElementById("email").scrollIntoView({ block: 'center',  behavior: 'smooth' });
          return false;
        } else {
          this.passwordState = null;
        }
        return true;
    }
  }
}
</script>

<style></style>

